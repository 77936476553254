import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ProductCard from "../components/product/ProductCard";
import { productService } from "../services/productService";

const Home = () => {
	const [featuredProducts, setFeaturedProducts] = useState([]);
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchHomeData = async () => {
			setLoading(true);
			setError(null);

			try {
				// Fetch featured products
				const productsData = await productService.getFeaturedProducts();
				setFeaturedProducts(productsData.data);

				// Fetch categories
				const categoriesData = await productService.getCategories();
				setCategories(categoriesData.data);
			} catch (err) {
				setError("Failed to load home page data");
				console.error("Error loading home page:", err);
			} finally {
				setLoading(false);
			}
		};

		fetchHomeData();
	}, []);

	if (loading) {
		return (
			<div className="text-center py-5">
				<div className="spinner-border text-primary" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			</div>
		);
	}

	if (error) {
		return (
			<div className="container py-5 text-center">
				<div className="alert alert-danger" role="alert">
					{error}
					<button
						className="btn btn-outline-danger ms-3"
						onClick={() => window.location.reload()}
					>
						Try Again
					</button>
				</div>
			</div>
		);
	}

	return (
		<div>
			{/* Hero Section */}
			<div className="bg-primary text-white py-5">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-md-6">
							<h1 className="display-4">Welcome to Our Store</h1>
							<p className="lead">Discover amazing products at great prices</p>
							<Link to="/products" className="btn btn-light btn-lg">
								Shop Now
							</Link>
						</div>
						<div className="col-md-6">
							<img
								src="https://via.placeholder.com/600x400"
								alt="Hero"
								className="img-fluid rounded"
							/>
						</div>
					</div>
				</div>
			</div>

			{/* Featured Products Section */}
			<div className="container my-5">
				<h2 className="text-center mb-4">Featured Products</h2>
				<div className="row g-4">
					{featuredProducts.map((product) => (
						<div key={product.id} className="col-md-4">
							<ProductCard product={product} />
						</div>
					))}
				</div>
			</div>

			{/* Categories Section */}
			<div className="bg-light py-5">
				<div className="container">
					<h2 className="text-center mb-4">Shop by Category</h2>
					<div className="row g-4">
						{categories.map((category) => (
							<div key={category.id} className="col-md-4">
								<div className="card h-100">
									{category.image && (
										<img
											src={category.image}
											className="card-img-top"
											alt={category.name}
											style={{ height: "200px", objectFit: "cover" }}
										/>
									)}
									<div className="card-body text-center">
										<h3>{category.name}</h3>
										<p className="text-muted">{category.description}</p>
										<Link
											to={`/products?category=${category.id}`}
											className="btn store-button"
										>
											View Products
										</Link>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
