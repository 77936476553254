import { publicApi, privateApi } from "../config/axios";

export const productService = {
	// Public store endpoints
	getProducts: async (params = {}) => {
		const response = await publicApi.get("products", { params });
		return response;
	},

	getFeaturedProducts: async () => {
		const response = await publicApi.get("products/featured");
		return response;
	},

	getProduct: async (id) => {
		const response = await publicApi.get(`products/${id}`);
		return response;
	},

	getCategories: async () => {
		const response = await publicApi.get("categories");
		return response;
	},

	getCategory: async (id) => {
		const response = await publicApi.get(`categories/${id}`);
		return response;
	},
};
