import React from "react";

const Footer = () => {
	return (
		<footer className="store-footer mt-auto">
			<div className="container">
				<div className="row">
					<div className="col-md-4">
						<h5>About Us</h5>
						<p>Your trusted online store for quality products.</p>
					</div>
					<div className="col-md-4">
						<h5>Quick Links</h5>
						<ul className="list-unstyled">
							<li>
								<a href="/products" className="text-light">
									Products
								</a>
							</li>
							<li>
								<a href="/cart" className="text-light">
									Cart
								</a>
							</li>
							<li>
								<a href="/contact" className="text-light">
									Contact
								</a>
							</li>
						</ul>
					</div>
					<div className="col-md-4">
						<h5>Contact Info</h5>
						<ul className="list-unstyled">
							<li>
								<i className="bi bi-envelope"></i> contact@store.com
							</li>
							<li>
								<i className="bi bi-phone"></i> +1 234 567 890
							</li>
						</ul>
					</div>
				</div>
				<hr className="bg-light" />
				<div className="text-center">
					<p>&copy; 2024 Store Demo. All rights reserved.</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
