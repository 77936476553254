import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ product, viewMode = "grid" }) => {
	const { id, name, price, image, description, rating, brand } = product;

	const renderRatingStars = (rating) => {
		return [...Array(5)].map((_, index) => (
			<i
				key={index}
				className={`bi ${
					index < Math.floor(rating)
						? "bi-star-fill"
						: index < rating
						? "bi-star-half"
						: "bi-star"
				} text-warning`}
			></i>
		));
	};

	if (viewMode === "list") {
		return (
			<div className="store-card card h-100">
				<div className="row g-0">
					<div className="col-md-4">
						<img
							src={image}
							className="card-img-top product-image h-100"
							alt={name}
							style={{ objectFit: "contain" }}
						/>
					</div>
					<div className="col-md-8">
						<div className="card-body d-flex flex-column h-100">
							<div className="d-flex justify-content-between align-items-start">
								<h5 className="card-title mb-1">{name}</h5>
								<span className="badge bg-primary">{brand}</span>
							</div>
							<div className="mb-2">
								{renderRatingStars(rating)}
								<small className="text-muted ms-2">({rating})</small>
							</div>
							<p className="card-text">{description}</p>
							<p className="card-text text-primary h5 mb-3">${price}</p>
							<div className="mt-auto">
								<Link to={`/product/${id}`} className="btn store-button me-2">
									View Details
								</Link>
								<button
									className="btn btn-outline-primary"
									onClick={() => {
										/* Add to cart function */
									}}
								>
									Add to Cart
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="store-card card h-100">
			<div className="position-relative">
				<img
					src={image}
					className="card-img-top product-image"
					alt={name}
					style={{ height: "200px", objectFit: "cover" }}
				/>
				<span className="position-absolute top-0 end-0 badge bg-primary m-2">
					{brand}
				</span>
			</div>
			<div className="card-body d-flex flex-column">
				<h5 className="card-title mb-1">{name}</h5>
				<div className="mb-2">
					{renderRatingStars(rating)}
					<small className="text-muted ms-2">({rating})</small>
				</div>
				<p className="card-text text-truncate">{description}</p>
				<p className="card-text text-primary h5 mb-3">${price}</p>
				<div className="mt-auto">
					<Link to={`/product/${id}`} className="btn store-button me-2">
						View Details
					</Link>
					<button
						className="btn btn-outline-primary"
						onClick={() => {
							/* Add to cart function */
						}}
					>
						<i className="bi bi-cart-plus"></i>
					</button>
				</div>
			</div>
		</div>
	);
};

export default ProductCard;
