import axios from "axios";

// Create API instance for public endpoints (no auth required)
export const publicApi = axios.create({
	baseURL: `${process.env.REACT_APP_API_BASE_URL}/store`,
	timeout: process.env.REACT_APP_API_TIMEOUT || 10000,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
	},
});

// Create API instance for private endpoints (auth required)
export const privateApi = axios.create({
	baseURL: `${process.env.REACT_APP_API_BASE_URL}/`,
	timeout: process.env.REACT_APP_API_TIMEOUT || 10000,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
	},
});

// Create API instance for auth endpoints
export const authApi = axios.create({
	baseURL: `${process.env.REACT_APP_API_BASE_URL}/auth`,
	timeout: process.env.REACT_APP_API_TIMEOUT || 10000,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
	},
});

// Request interceptor for private API
privateApi.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem("token");
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// Response interceptor for both APIs
const handleResponse = (response) => response.data;

const handleError = (error) => {
	if (error.response) {
		console.error("API Error:", error.response.data);

		switch (error.response.status) {
			case 401:
				localStorage.removeItem("token");
				window.location.href = "/login";
				break;
			case 403:
				console.error("Forbidden access");
				break;
			case 404:
				console.error("Resource not found");
				break;
			case 500:
				console.error("Server error");
				break;
			default:
				break;
		}
	} else if (error.request) {
		console.error("Network Error:", error.request);
	} else {
		console.error("Error:", error.message);
	}
	return Promise.reject(error);
};

publicApi.interceptors.response.use(handleResponse, handleError);
privateApi.interceptors.response.use(handleResponse, handleError);
authApi.interceptors.response.use(handleResponse, handleError);
