import React from "react";
import { Link } from "react-router-dom";
import CartItem from "../components/cart/CartItem";

const Cart = () => {
	// Mock cart items - replace with actual data later
	const cartItems = [
		{
			id: 1,
			name: "Product 1",
			price: 99.99,
			quantity: 2,
			image: "https://via.placeholder.com/300",
		},
		// Add more cart items as needed
	];

	const handleUpdateQuantity = (item, newQuantity) => {
		// Implement quantity update logic
		console.log("Update quantity", item, newQuantity);
	};

	const handleRemoveItem = (item) => {
		// Implement remove item logic
		console.log("Remove item", item);
	};

	const calculateSubtotal = () => {
		return cartItems.reduce(
			(total, item) => total + item.price * item.quantity,
			0
		);
	};

	const calculateTax = () => {
		return calculateSubtotal() * 0.1; // 10% tax
	};

	const calculateTotal = () => {
		return calculateSubtotal() + calculateTax();
	};

	if (cartItems.length === 0) {
		return (
			<div className="container py-5 text-center">
				<h2>Your Cart is Empty</h2>
				<p>Looks like you haven't added any items to your cart yet.</p>
				<Link to="/products" className="btn store-button">
					Continue Shopping
				</Link>
			</div>
		);
	}

	return (
		<div className="container py-5">
			<h2 className="mb-4">Shopping Cart</h2>

			<div className="row">
				<div className="col-lg-8">
					{/* Cart Items */}
					{cartItems.map((item) => (
						<CartItem
							key={item.id}
							item={item}
							onUpdateQuantity={handleUpdateQuantity}
							onRemove={handleRemoveItem}
						/>
					))}
				</div>

				<div className="col-lg-4">
					{/* Order Summary */}
					<div className="card">
						<div className="card-body">
							<h3 className="card-title">Order Summary</h3>
							<div className="d-flex justify-content-between mb-2">
								<span>Subtotal</span>
								<span>${calculateSubtotal().toFixed(2)}</span>
							</div>
							<div className="d-flex justify-content-between mb-2">
								<span>Tax</span>
								<span>${calculateTax().toFixed(2)}</span>
							</div>
							<hr />
							<div className="d-flex justify-content-between mb-4">
								<strong>Total</strong>
								<strong>${calculateTotal().toFixed(2)}</strong>
							</div>
							<button className="btn store-button w-100 mb-2">
								Proceed to Checkout
							</button>
							<Link to="/products" className="btn btn-outline-primary w-100">
								Continue Shopping
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Cart;
