import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ProductCard from "../components/product/ProductCard";
import { productService } from "../services/productService";

const Products = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [selectedCategory, setSelectedCategory] = useState(
		searchParams.get("category") || "all"
	);
	const [sortBy, setSortBy] = useState("default");
	const [searchQuery, setSearchQuery] = useState(searchParams.get("q") || "");
	const [priceRange, setPriceRange] = useState({ min: "", max: "" });
	const [selectedBrands, setSelectedBrands] = useState([]);
	const [viewMode, setViewMode] = useState("grid");

	// State for API data
	const [products, setProducts] = useState([]);
	const [categories, setCategories] = useState([]);
	const [brands, setBrands] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	// Fetch categories on mount
	useEffect(() => {
		const fetchCategories = async () => {
			try {
				const categoriesData = await productService.getCategories();
				setCategories([
					{ id: "all", name: "All Products", icon: "bi-grid-fill" },
					...categoriesData.data.map((category) => ({
						...category,
						icon: getIconForCategory(category.name),
					})),
				]);
			} catch (err) {
				setError("Failed to load categories");
				console.error("Error loading categories:", err);
			}
		};

		fetchCategories();
	}, []);

	// Fetch products when filters change
	useEffect(() => {
		const fetchProducts = async () => {
			setLoading(true);
			setError(null);

			try {
				let productsData;
				const params = {
					sort: sortBy,
					min_price: priceRange.min || undefined,
					max_price: priceRange.max || undefined,
					brands: selectedBrands.join(",") || undefined,
				};

				if (searchQuery) {
					productsData = await productService.searchProducts(
						searchQuery,
						params
					);
				} else if (selectedCategory !== "all") {
					productsData = await productService.getProductsByCategory(
						selectedCategory,
						params
					);
				} else {
					productsData = await productService.getProducts(params);
				}

				setProducts(productsData.data);

				// Extract unique brands from products
				const uniqueBrands = [
					...new Set(productsData.data.map((product) => product.brand)),
				];
				setBrands(uniqueBrands);
			} catch (err) {
				setError("Failed to load products");
				console.error("Error loading products:", err);
			} finally {
				setLoading(false);
			}
		};

		fetchProducts();
	}, [
		selectedCategory,
		sortBy,
		searchQuery,
		priceRange.min,
		priceRange.max,
		selectedBrands,
	]);

	const handleSearch = (e) => {
		e.preventDefault();
		setSearchParams({
			category: selectedCategory,
			q: searchQuery,
		});
	};

	const handleBrandToggle = (brand) => {
		setSelectedBrands((prev) =>
			prev.includes(brand) ? prev.filter((b) => b !== brand) : [...prev, brand]
		);
	};

	const getIconForCategory = (categoryName) => {
		const iconMap = {
			Electronics: "bi-laptop",
			Fashion: "bi-bag",
			Home: "bi-house",
			Books: "bi-book",
			Sports: "bi-bicycle",
			Beauty: "bi-gem",
			// Add more mappings as needed
		};
		return iconMap[categoryName] || "bi-tag";
	};

	if (error) {
		return (
			<div className="container py-5 text-center">
				<div className="alert alert-danger" role="alert">
					{error}
					<button
						className="btn btn-outline-danger ms-3"
						onClick={() => window.location.reload()}
					>
						Try Again
					</button>
				</div>
			</div>
		);
	}

	return (
		<div className="container-fluid py-5">
			<div className="row">
				{/* Sidebar Filters */}
				<div className="col-lg-3">
					<div className="card shadow-sm">
						<div className="card-body">
							<h5 className="card-title mb-4">Filters</h5>

							{/* Categories */}
							<div className="mb-4">
								<h6 className="mb-3">Categories</h6>
								<div className="list-group">
									{categories.map((category) => (
										<button
											key={category.id}
											className={`list-group-item list-group-item-action d-flex align-items-center ${
												selectedCategory === category.id ? "active" : ""
											}`}
											onClick={() => setSelectedCategory(category.id)}
										>
											<i className={`bi ${category.icon} me-2`}></i>
											{category.name}
										</button>
									))}
								</div>
							</div>

							{/* Price Range */}
							<div className="mb-4">
								<h6 className="mb-3">Price Range</h6>
								<div className="row g-2">
									<div className="col-6">
										<input
											type="number"
											className="form-control"
											placeholder="Min"
											value={priceRange.min}
											onChange={(e) =>
												setPriceRange((prev) => ({
													...prev,
													min: e.target.value,
												}))
											}
										/>
									</div>
									<div className="col-6">
										<input
											type="number"
											className="form-control"
											placeholder="Max"
											value={priceRange.max}
											onChange={(e) =>
												setPriceRange((prev) => ({
													...prev,
													max: e.target.value,
												}))
											}
										/>
									</div>
								</div>
							</div>

							{/* Brands */}
							<div className="mb-4">
								<h6 className="mb-3">Brands</h6>
								{brands.map((brand) => (
									<div key={brand} className="form-check">
										<input
											className="form-check-input"
											type="checkbox"
											id={`brand-${brand}`}
											checked={selectedBrands.includes(brand)}
											onChange={() => handleBrandToggle(brand)}
										/>
										<label
											className="form-check-label"
											htmlFor={`brand-${brand}`}
										>
											{brand}
										</label>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>

				{/* Main Content */}
				<div className="col-lg-9">
					{/* Search and Sort Bar */}
					<div className="card shadow-sm mb-4">
						<div className="card-body">
							<div className="row align-items-center">
								<div className="col-md-6 mb-3 mb-md-0">
									<form onSubmit={handleSearch} className="d-flex">
										<input
											type="search"
											className="form-control me-2"
											placeholder="Search products..."
											value={searchQuery}
											onChange={(e) => setSearchQuery(e.target.value)}
										/>
										<button type="submit" className="btn store-button">
											<i className="bi bi-search"></i>
										</button>
									</form>
								</div>
								<div className="col-md-6">
									<div className="d-flex justify-content-md-end align-items-center">
										<select
											className="form-select me-2"
											style={{ width: "auto" }}
											value={sortBy}
											onChange={(e) => setSortBy(e.target.value)}
										>
											<option value="default">Sort by</option>
											<option value="price_asc">Price: Low to High</option>
											<option value="price_desc">Price: High to Low</option>
											<option value="name_asc">Name</option>
											<option value="rating_desc">Rating</option>
										</select>
										<div className="btn-group">
											<button
												className={`btn btn-outline-primary ${
													viewMode === "grid" ? "active" : ""
												}`}
												onClick={() => setViewMode("grid")}
											>
												<i className="bi bi-grid"></i>
											</button>
											<button
												className={`btn btn-outline-primary ${
													viewMode === "list" ? "active" : ""
												}`}
												onClick={() => setViewMode("list")}
											>
												<i className="bi bi-list"></i>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Products Display */}
					{loading ? (
						<div className="text-center py-5">
							<div className="spinner-border text-primary" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
					) : products.length > 0 ? (
						<div
							className={`row g-4 ${viewMode === "list" ? "flex-column" : ""}`}
						>
							{products.map((product) => (
								<div
									key={product.id}
									className={
										viewMode === "list" ? "col-12" : "col-md-6 col-lg-4"
									}
								>
									<ProductCard product={product} viewMode={viewMode} />
								</div>
							))}
						</div>
					) : (
						<div className="text-center py-5">
							<i className="bi bi-search display-1 text-muted"></i>
							<h3 className="mt-3">No products found</h3>
							<p className="text-muted">
								Try adjusting your search or filter criteria
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Products;
