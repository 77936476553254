import React from "react";

const CartItem = ({ item, onUpdateQuantity, onRemove }) => {
	const { name, price, quantity, image } = item;

	return (
		<div className="cart-item card mb-3">
			<div className="row g-0">
				<div className="col-md-2">
					<img
						src={image}
						className="img-fluid rounded-start"
						alt={name}
						style={{ height: "100px", objectFit: "cover" }}
					/>
				</div>
				<div className="col-md-10">
					<div className="card-body">
						<div className="row">
							<div className="col-md-6">
								<h5 className="card-title">{name}</h5>
								<p className="card-text text-primary fw-bold">${price}</p>
							</div>
							<div className="col-md-4">
								<div className="input-group">
									<button
										className="btn btn-outline-secondary"
										type="button"
										onClick={() => onUpdateQuantity(item, quantity - 1)}
										disabled={quantity <= 1}
									>
										-
									</button>
									<input
										type="number"
										className="form-control text-center"
										value={quantity}
										readOnly
									/>
									<button
										className="btn btn-outline-secondary"
										type="button"
										onClick={() => onUpdateQuantity(item, quantity + 1)}
									>
										+
									</button>
								</div>
							</div>
							<div className="col-md-2">
								<button
									className="btn btn-outline-danger"
									onClick={() => onRemove(item)}
								>
									<i className="bi bi-trash"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CartItem;
