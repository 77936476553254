import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Cart from "./pages/Cart";
import ProductDetail from "./pages/ProductDetail";

function App() {
	return (
		<Router>
			<div className="d-flex flex-column min-vh-100">
				<Navbar />
				<main className="flex-grow-1">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/products" element={<Products />} />
						<Route path="/product/:id" element={<ProductDetail />} />
						<Route path="/cart" element={<Cart />} />
					</Routes>
				</main>
				<Footer />
			</div>
		</Router>
	);
}

export default App;
