import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import ProductCard from "../components/product/ProductCard";
import { productService } from "../services/productService";

const ProductDetail = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [selectedImage, setSelectedImage] = useState(0);
	const [product, setProduct] = useState(null);
	const [relatedProducts, setRelatedProducts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchProductData = async () => {
			setLoading(true);
			setError(null);

			try {
				// Fetch product details
				const productData = await productService.getProduct(id);
				setProduct(productData.data);
				setSelectedImage(0); // Reset selected image when product changes

				// TODO : Fetch related products
				// const relatedData = await productService.getRelatedProducts(id);
				// setRelatedProducts(relatedData);
			} catch (err) {
				setError("Failed to load product details");
				console.error("Error loading product:", err);
			} finally {
				setLoading(false);
			}
		};

		fetchProductData();
	}, [id]);

	if (loading) {
		return (
			<div className="container py-5 text-center">
				<div className="spinner-border text-primary" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			</div>
		);
	}

	if (error) {
		return (
			<div className="container py-5 text-center">
				<div className="alert alert-danger" role="alert">
					{error}
					<button
						className="btn btn-outline-danger ms-3"
						onClick={() => navigate("/products")}
					>
						Back to Products
					</button>
				</div>
			</div>
		);
	}

	if (!product) {
		return (
			<div className="container py-5 text-center">
				<h2>Product Not Found</h2>
				<p>The product you're looking for doesn't exist.</p>
				<Link to="/products" className="btn store-button">
					Back to Products
				</Link>
			</div>
		);
	}

	return (
		<div className="container py-5">
			<nav aria-label="breadcrumb" className="mb-4">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<Link to="/">Home</Link>
					</li>
					<li className="breadcrumb-item">
						<Link to="/products">Products</Link>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						{product.name}
					</li>
				</ol>
			</nav>

			<div className="row">
				{/* Product Images */}
				<div className="col-md-6 mb-4">
					<div className="product-image-gallery">
						<img
							src={product?.images?.[selectedImage]}
							alt={product?.name}
							className="img-fluid rounded main-product-image mb-3"
						/>
						<div className="d-flex gap-2">
							{product?.images?.map((image, index) => (
								<img
									key={index}
									src={image}
									alt={`${product?.name} view ${index + 1}`}
									className={`thumbnail-image ${
										selectedImage === index ? "selected" : ""
									}`}
									onClick={() => setSelectedImage(index)}
									style={{ width: "80px", cursor: "pointer" }}
								/>
							))}
						</div>
					</div>
				</div>

				{/* Product Info */}
				<div className="col-md-6">
					<div className="mb-3 d-flex justify-content-between align-items-start">
						<h1 className="mb-0">{product.name}</h1>
						<span className="badge bg-primary">{product.brand}</span>
					</div>
					<div className="mb-3">
						{[...Array(5)].map((_, index) => (
							<i
								key={index}
								className={`bi ${
									index < Math.floor(product.rating)
										? "bi-star-fill"
										: index < product.rating
										? "bi-star-half"
										: "bi-star"
								} text-warning`}
							></i>
						))}
						<small className="text-muted ms-2">({product.rating})</small>
					</div>
					<p className="text-primary h2 mb-4">${product.price}</p>

					<div className="mb-4">
						<h5>Description</h5>
						<p>{product.description}</p>
					</div>

					<div className="mb-4">
						<h5>Specifications</h5>
						<table className="table">
							<tbody>
								{product.specs ? (
									Object.entries(product.specs).map(([key, value]) => (
										<tr key={key}>
											<td className="fw-bold">{key}</td>
											<td>{value}</td>
										</tr>
									))
								) : (
									<tr>
										<td colSpan="2" className="text-muted">
											No specifications available
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>

					<div className="mb-4">
						<div className="d-flex align-items-center mb-3">
							<span
								className={`badge ${
									product.stock > 0 ? "bg-success" : "bg-danger"
								} me-2`}
							>
								{product.stock > 0 ? "In Stock" : "Out of Stock"}
							</span>
							{product.stock > 0 && (
								<small>{product.stock} units available</small>
							)}
						</div>

						<div className="d-grid gap-2">
							<button
								className="btn store-button btn-lg"
								disabled={product.stock === 0}
							>
								Add to Cart
							</button>
							<button className="btn btn-outline-primary">
								Add to Wishlist
							</button>
						</div>
					</div>
				</div>
			</div>

			{/* Related Products */}
			{relatedProducts.length > 0 && (
				<div className="mt-5">
					<h3 className="mb-4">Related Products</h3>
					<div className="row g-4">
						{relatedProducts.map((product) => (
							<div key={product.id} className="col-md-4">
								<ProductCard product={product} />
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default ProductDetail;
