import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
	return (
		<nav className="navbar navbar-expand-lg store-nav">
			<div className="container">
				<Link className="navbar-brand" to="/">
					Store Demo
				</Link>
				<button
					className="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarNav"
				>
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarNav">
					<ul className="navbar-nav me-auto">
						<li className="nav-item">
							<Link className="nav-link" to="/">
								Home
							</Link>
						</li>
						<li className="nav-item">
							<Link className="nav-link" to="/products">
								Products
							</Link>
						</li>
					</ul>
					<div className="d-flex">
						<Link to="/cart" className="btn store-button">
							<i className="bi bi-cart"></i>
							<span className="ms-2">Cart</span>
						</Link>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
